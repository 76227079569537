<template>
  <div>
    <vs-popup title="Filter" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full -mt-1">
            <label class="ml-1 text-xs">Status Approval</label>

            <v-select :options="['PROSES', 'DITERIMA', 'DIREVISI', 'DITOLAK']" :clearable="false" v-model="data.progress_unit_status_approval"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" color="danger" type="border" @click="clearFilter" :disabled="isEmptyFilter">Clear Filter</vs-button>
            <vs-button class="mr-3" color="primary" @click="filter" :disabled="isEmptyFilter">Filter</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'ProgressUnitFilter',
  props: ['isActive'],
  components: {
    'v-select': vSelect
  },
  mounted () {},
  computed: {
    isEmptyFilter () {
      return _.isEmpty(this.data)
    }
  },
  data () {
    return {
      errors: null,
      data: {
        progress_unit_status_approval: null
      }
    }
  },
  methods: {
    filter () {
      this.$emit('filter', this.data)
      this.emitModalIsActive(false)
    },

    clearFilter () {
      this.data = {}
      this.$emit('filter', {})
      this.emitModalIsActive(false)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
